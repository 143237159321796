<template>
  <div>
    <div class="selec-inp">
      <!-- <div class="_Select select-name">
        <span>巡查人员：</span>
        <el-input
          class="input-name"
          type="text"
          v-model="userName"
          placeholder="请按名称查询"
          @input="searchPersons"
        />
        <el-select
          ref="selectRef"
          v-model="persons"
          :loading="loading"
          loading-text="加载中..."
          placeholder="请选择"
        >
          <el-option
            v-for="item in personList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <el-date-picker v-model="dateFrom" type="date" placeholder="开始时间">
      </el-date-picker>
      <el-date-picker v-model="dateTo" type="date" placeholder="结束时间">
      </el-date-picker>
      <el-button
        icon="el-icon-search"
        style="margin-left: 10px"
        type="success"
        @click="getRecordList()"
      >
        查询
      </el-button>
      <el-button
        v-if="!showUser"
        style="margin-left: 10px"
        @click="recordToBack"
        icon="el-icon-back"
        >返回</el-button
      >
    </div>
    <el-table
      :data="tableData"
      v-if="showUser"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      style="width: 100%"
      id="table"
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      :height="elementHeight"
      border
      class="table"
    >
      <el-table-column type="selection" width="120"> </el-table-column>
      <el-table-column prop="userName" label="姓名" width="150">
        <template slot-scope="scope">
          <span @click="handleShowUser(scope.row)">{{
            scope.row.userName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="count" label="数量" width="150">
        <template slot-scope="scope">
          <span @click="handleShowUser(scope.row)">{{ scope.row.count }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="questionCount" label="发现问题个数">
        <template slot-scope="scope">
          <span @click="toPage(scope.row)">{{ scope.row.questionCount }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="tableData"
      v-if="!showUser"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      style="width: 100%"
      id="table"
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle2"
      @row-click="rowClick"
      :height="elementHeight"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="saveTime" label="时间">
        <template slot-scope="scope">
          <span>{{
            scope.row.saveTime ? scope.row.saveTime.replace('T', ' ') : ''
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="姓名"> </el-table-column>
      <el-table-column prop="catalogueName" label="查看公司"> </el-table-column>
      <el-table-column prop="videoName" label="摄像头编号"> </el-table-column>
    </el-table>
    <el-pagination
      v-if="!showUser"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageNo"
      :page-sizes="[10, 20, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import { get } from '../../api/http'
export default {
  data() {
    return {
      tableData: [],
      elementHeight: 0,
      tableRowIndex: null,
      tableRowIndex2: null,
      userName: '',
      personListSele: [],
      loading: false,
      persons: '',
      dateFrom: '',
      dateTo: '',
      showUser: true,
      // 分页
      pageNo: 1,
      pageSize: 10,
    }
  },
  mounted() {
    this.getElementHeight()
    this.getPurview()
  },
  methods: {
    getPurview() {
      get('/api/OAUsers')
        .then((resp) => {
          if (resp.code == 200) {
            var userNo = JSON.parse(sessionStorage.getItem('userInfo')).userNo
            let user = resp.data.find((i) => i.userNo == userNo)
            if (user) {
              this.getRecordList()
            } else {
              this.$confirm('抱歉，您没有权限')
                .then(() => {
                  this.$router.go(-1)
                })
                .catch(() => {
                  this.$router.go(-1)
                })
            }
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('系统异常，请稍后再试')
        })
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 52 + 20 + 45) //分页加底部距离
      })
    },
    getRecordList() {
      var url = ''
      if (!this.showUser) {
        url =
          '/api/VideoRecord/GetPageByUser?UserId=' +
          this.persons +
          '&DateFrom=' +
          (this.dateFrom ? new Date(this.dateFrom).toISOString() : '') +
          '&DateTo=' +
          (this.dateTo ? new Date(this.dateTo).toISOString() : '') +
          '&PageNo=' +
          this.pageNo +
          '&PageSize=' +
          this.pageSize
      } else {
        url =
          '/api/VideoRecord/GetUserRecord?DateFrom=' +
          (this.dateFrom ? new Date(this.dateFrom).toISOString() : '') +
          '&DateTo=' +
          (this.dateTo ? new Date(this.dateTo).toISOString() : '')
      }
      get(url).then((resp) => {
        this.tableRowIndex2 = null
        this.tableData = resp.data
        this.dataCount = resp.count
      })
    },
    handleShowUser(row) {
      this.showUser = false
      this.pageNo = 1
      this.persons = row.userId
      this.tableData2 = JSON.parse(JSON.stringify(this.tableData))
      this.tableRowIndex = row.index
      this.getRecordList()
    },
    toPage(row) {
      this.$router.push(
        './monitoring-issues?userName=' + row.userName + '&userNo=' + row.userNo
      )
    },
    rowClick(row) {
      this.tableRowIndex2 = row.index
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
      if (rowIndex % 2 == 0) {
        return ''
      } else {
        return 'warning-row'
      }
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          border: '5px solid',
          'background-color': '#E2E8FA !important',
        }
      }
    },
    selectedstyle2({ rowIndex }) {
      if (this.tableRowIndex2 == rowIndex) {
        return {
          border: '5px solid',
          'background-color': '#E2E8FA !important',
        }
      }
    },
    recordToBack() {
      this.showUser = true
      this.tableData = this.tableData2
    },
    // searchPersons() {
    //   if (!this.userName) {
    //     this.persons = ''
    //     return
    //   }
    //   this.$refs.selectRef.toggleMenu()
    //   this.loading = true
    //   get('/api/User?UserNo=' + '&UserName=' + this.userName).then((resp) => {
    //     if (resp.code == 200) {
    //       this.personList = resp.data
    //       this.loading = false
    //     }
    //   })
    // },
    // 分页
    handleSizeChange(e) {
      this.pageSize = e
      this.pageNo = 1
    },
    handleCurrentChange(e) {
      this.pageNo = e
      this.getRecordList()
    },
  },
}
</script>

<style lang="less" scoped>
.selec-inp {
  display: flex;
  align-items: center;
  padding: 20px 0;
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 180px !important;
  }
}
.select-name {
  width: 400px !important;
  margin-right: 10px;
  .el-select {
    width: 280px !important;
    /deep/.el-select__tags {
      overflow: hidden !important;
      flex-wrap: nowrap !important;
    }
    input {
      border: none;
      outline: none !important;
    }
  }
  .input-name {
    width: 180px !important;
    border: 1px solid #c0c0c0d5;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 13px !important;
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0fc;
  }
  :-moz-placeholder {
    color: #c0c0c0fc;
  }
  ::moz-placeholder {
    color: #c0c0c0fc;
  }
  :-ms-input-placeholder {
    color: #c0c0c0fc;
  }
}
/deep/ .el-table .el-table__cell {
  text-align: start !important;
  padding-left: 40px !important;
}
/deep/ .table td:nth-of-type(2),
/deep/ .table td:nth-of-type(3),
/deep/ .table td:nth-of-type(4) {
  color: #0066ff !important;
  cursor: pointer;
  .cell {
    border-bottom: 1px solid;
    display: inline-block;
    padding: 0 !important;
    margin: 0 10px;
  }
}
</style>